<template>
  <div>
    <el-card>
      <el-form inline size="mini">
        <el-form-item label="老兵姓名:">
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item label="性别:">
          <el-select v-model="form.sex">
            <el-option label="男" value="男" />
            <el-option label="女" value="女" />
          </el-select>
        </el-form-item>
        <el-form-item label="番号:">
          <el-input v-model="form.number" />
        </el-form-item>
        <el-form-item label="编号:">
          <el-input v-model="form.id" />
        </el-form-item>
        <el-form-item label="籍贯:">
          <el-select v-model="form.hometown" placeholder="请选择籍贯">
            <el-option
              v-for="item in province"
              :key="item.c"
              :label="item.n"
              :value="item.n"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="阵亡时间:">
          <el-date-picker v-model="form.start" size="small" value-format="yyyy-MM-dd" placeholder="选择日期" />至
          <el-date-picker v-model="form.end" size="small" value-format="yyyy-MM-dd" placeholder="选择日期" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="getDataList">查询</el-button>
          <el-button type="primary" size="mini" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-form inline>
        <el-form-item>
          <el-button size="mini" type="primary" @click="openAddVeteran">添加老兵</el-button>
          <el-button size="mini" type="primary" @click="deleteAll">批量删除</el-button>
          <el-button size="mini" type="primary" @click="importVisible = true">批量导入</el-button>
          <el-button size="mini" type="primary" @click="downloadTemp">下载模板</el-button>
        </el-form-item>
      </el-form>
      <el-table
        v-loading="dataListLoading"
        :data="tableList"
        style="width: 100%;padding: 8px 0 ! important;"
        :header-cell-style="{background:'#F7F7F7',color:'#1E1E1E'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="老兵姓名" prop="name" align="center" />
        <el-table-column label="性别" prop="sex" align="center" />
        <el-table-column label="编号" prop="id" align="center" />
        <el-table-column label="籍贯" prop="hometown" align="center" />
        <el-table-column label="番号" prop="number" align="center" />
        <el-table-column label="老兵状态" prop="veteranStatus" align="center" />
        <el-table-column label="阵亡时间" align="center">
          <template slot-scope="{row}">
            <span>{{ row.sacrificeDate && row.sacrificeDate | F_formatDate() }}</span>
          </template>
        </el-table-column>
        <el-table-column label="阵亡地点" prop="sacrificePlace" align="center" />
        <el-table-column label="资料来源" prop="source" align="center" />
        <el-table-column label="操作">
          <template slot-scope="{row}">
            <el-button type="text" @click="getDetail(row.id)">详情</el-button>
            <el-button type="text" @click="openEditVeteran(row.id)">修改</el-button>
            <el-button type="text" @click="deleteVeteran(row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :current-page="current" :page-sizes="[10, 20, 50, 100]" :page-size="size" :total="total" layout="total, sizes, prev, pager, next, jumper" style="padding:20px;" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </el-card>
    <!-- 批量导入 -->
    <el-dialog :visible.sync="importVisible" title="导入老兵" :close-on-click-modal="false" width="400px">
      <el-upload class="upload-demo" drag :action="action" name="multipartFile" :data="uploadData" :before-upload="beforeTableUpload" :on-success="uploadSuccess">
        <i class="el-icon-upload" />
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div slot="tip" class="el-upload__tip">支持xlsx文件</div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeDialog">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 添加老兵 -->
    <add-veteran ref="addVeteran" @addSuccess="getDataList" />
    <!-- 老兵详情 -->
    <veteran-detail ref="veteranDetail" />
  </div>
</template>

<script>
import { veteranInfoList, deleteVeteran, deleteAllVeteran } from '@/utils/api.js'
import { mapState } from 'vuex'
import httpRequest from '@/utils/httpRequest.js'
import AddVeteran from './components/AddVeteran'
import VeteranDetail from './components/VeteranDetail'
import province from '@/plugins/province'
import { host } from '@/config'
export default {
  components: { AddVeteran, VeteranDetail },
  data() {
    return {
      form: {},
      tableList: [],
      dataListLoading: true,
      value: '',
      importVisible: false,
      current: 1, // 当前页
      size: 10, // 每页条数
      total: 0, // 总条数
      action: httpRequest.api('/veteranInfo/import'),
      province: province,
      multipleSelection: []
      // uploadData: { auth:this.auth}
    }
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth
    }),
    uploadData() {
      console.log(this.auth)
      return { auth: this.auth }
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    deleteAll() {
      if (!this.multipleSelection.length) {
        return this.$message.warning('至少选中一项')
      }
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const ids = this.multipleSelection.map(item => {
          return item.id
        })
        const res = await deleteAllVeteran(ids)
        if (res.code === 200) {
          this.getDataList()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        } else {
          this.$message.error(res.message)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    closeDialog() {
      this.importVisible = false
      this.getDataList()
    },
    openAddVeteran() {
      this.$refs.addVeteran.open()
    },
    openEditVeteran(id) {
      this.$refs.addVeteran.open(id)
    },
    async getDataList() {
      console.log('这是获取数据')
      this.dataListLoading = true
      this.form.companyId = sessionStorage.companyId
      const res = await veteranInfoList(this.form, this.current, this.size)
      this.dataListLoading = false
      if (res.code === 200) {
        this.tableList = res.data.records
        this.total = res.data.total
      } else {
        this.$message.error(res.message)
        this.dataListLoading = false
      }
    },
    reset() {
      this.form = {}
      this.getDataList()
    },
    getDetail(id) {
      this.$refs.veteranDetail.open(id)
    },
    deleteVeteran(id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteVeteran({}, id).then(res => {
          this.getDataList()
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 导入表格文件前的回调
    beforeTableUpload(file) {
      const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1)
      const isXlsx = fileExtension === 'xlsx'
      if (!isXlsx) {
        this.$message.error('上传文件必须是xlsx文件')
      }
      return isXlsx
    },
    // 文件上传成功时的回调
    uploadSuccess(res) {
      console.log('上传成功了')
      this.$message({
        message: res.data || res.message,
        type: 'success',
        duration: 3000
      })
    },
    // 下载模板
    async downloadTemp() {
      const fileurl = `${host}/veteranInfo/downModel`
      window.location.href = fileurl
    },
    // 每页数
    handleSizeChange(val) {
      this.size = val
      this.current = 1
      this.getDataList()
    },
    // 当前页
    handleCurrentChange(val) {
      this.current = val
      this.getDataList()
    }
  }
}
</script>

<style lang="less" scoped>
</style>


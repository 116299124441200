<template>
  <div>
    <el-dialog
      :visible.sync="visible"
      :title="title"
      width="1200px"
      @close="handleClose"
    >
      <div>
        <el-form inline size="small" label-width="100px">
          <el-form-item label="老兵头像:">
            <el-upload
              class="avatar-uploader"
              :action="action"
              :show-file-list="false"
              name="file"
              :data="uploadData"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="addForm.avatar" :src="addForm.avatar" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon" />
            </el-upload>
          </el-form-item>
          <el-form-item>
            <span style="font-size: 12px; color: #999999"
              >只能上传一张，尺寸为200*200</span
            >
          </el-form-item>
        </el-form>
        <el-form
          ref="addForm"
          inline
          size="small"
          label-width="100px"
          :model="addForm"
          :rules="rules"
        >
          <el-form-item label="老兵姓名" prop="name">
            <el-input v-model="addForm.name" />
          </el-form-item>
          <el-form-item label="老兵性别:">
            <el-radio-group v-model="addForm.sex">
              <el-radio label="男">男</el-radio>
              <el-radio label="女">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="出生日期:">
            <el-date-picker
              v-model="addForm.bornDate"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item label="老兵籍贯:">
            <el-input v-model="addForm.hometown" />
          </el-form-item>
          <el-form-item label="民族:">
            <el-select v-model="addForm.nation" placeholder="请选择">
              <el-option
                v-for="item in nationList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="入伍时间:">
            <el-date-picker
              v-model="addForm.enlistDate"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item label="入伍地点:">
            <el-input v-model="addForm.enlistPlace" />
          </el-form-item>
          <el-form-item label="是否黄埔">
            <el-radio-group v-model="addForm.isWhampoa">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
              <el-radio :label="2">未知</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="发现时间:">
            <el-date-picker
              v-model="addForm.findDate"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item label="去世时间:">
            <el-date-picker
              v-model="addForm.deathDate"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <!-- <el-form-item label="备考:">
            <el-input v-model="addForm.waitFind" />
          </el-form-item> -->
          <el-form-item label="部队番号:">
            <el-input v-model="addForm.number" />
          </el-form-item>
          <el-form-item label="资料来源:">
            <el-input v-model="addForm.source" />
          </el-form-item>
          <el-form-item label="老兵状态:" prop="veteranStatus">
            <el-select v-model="addForm.veteranStatus" placeholder="请选择">
              <el-option value="阵亡将士" />
              <el-option value="失踪者" />
              <el-option value="病故" />
              <el-option value="不详" />
              <el-option value="健在老兵" />
            </el-select>
          </el-form-item>
          <el-form-item label="老兵职级:">
            <el-input v-model="addForm.position" />
          </el-form-item>
          <el-form-item label="长官信息:">
            <el-input v-model="addForm.prefectInfo" />
          </el-form-item>
          <el-form-item label="阵亡时间:">
            <el-date-picker
              v-model="addForm.sacrificeDate"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item label="阵亡地点:">
            <el-input v-model="addForm.sacrificePlace" />
          </el-form-item>
        </el-form>
        <el-form inline size="small" label-width="100px">
          <el-form-item label="老兵图片:">
            <div class="picture-list">
              <span
                v-for="(item, index) in addForm.picture"
                :key="index"
                class="pictures-item"
              >
                <el-image
                  :src="item"
                  class="avatar"
                  :preview-src-list="[item]"
                />
                <span
                  class="pictures-delete"
                  @click="handleRemovePicture(index)"
                >
                  <i class="el-icon-close" />
                </span>
              </span>
              <el-upload
                class="avatar-uploader"
                :action="action"
                :show-file-list="false"
                :on-success="handlePictureSuccess"
                :before-upload="beforeAvatarUpload"
                multiple
                style="display: inline-block; margin-left: 5px"
              >
                <i class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item>
            <span style="font-size: 12px; color: #999999">
              可以上传5张，图片尺寸为750*400，大小不超过4MB
            </span>
          </el-form-item>
          <el-form-item label="海报图片:">
            <el-upload
              class="avatar-uploader"
              :action="action"
              :show-file-list="false"
              :on-success="handlePosterSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="addForm.poster" class="avatar" :src="addForm.poster" />
              <i v-else class="el-icon-plus avatar-uploader-icon" />
            </el-upload>
          </el-form-item>
          <el-form-item>
            <span style="font-size: 12px; color: #999999">
              图片尺寸为650*600，大小不超过4MB
            </span>
          </el-form-item>
          <el-form-item label="老兵介绍:">
            <Html-Editor
              ref="htmlEditor"
              :content.sync="addForm.introduction"
            />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import HtmlEditor from '@/components/HtmlEditor'
import {
  addVeteranInfo,
  veteranDetail,
  updateVeteranInfo
} from '@/utils/api.js'
import { host } from '@/config'
import httpRequest from '@/utils/httpRequest.js'
export default {
  components: { HtmlEditor },
  data() {
    return {
      visible: false,
      title: '添加老兵',
      addForm: {
        picture: [],
        sex: '男',
        avatar: '',
        bornDate: '',
        deathDate: '',
        detailId: '',
        enlistDate: '',
        enlistPlace: '',
        findDate: '',
        hometown: '',
        introduction: '',
        isWhampoa: '',
        liked: '',
        name: '',
        nation: '',
        number: '',
        position: '',
        poster: '',
        prefectInfo: '',
        sacrificeDate: '',
        sacrificePlace: '',
        source: '',
        veteranStatus: '',
        waitFind: ''
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入老兵姓名',
            trigger: 'blur'
          }
        ],
        veteranStatus: [
          {
            required: true,
            message: '请选择老兵状态',
            trigger: 'blur'
          }
        ]
      },
      action: httpRequest.api('/file/upload'),
      nationList: [
        {
          value: '汉',
          label: '汉'
        },
        {
          value: '蒙',
          label: '蒙'
        },
        {
          value: '回',
          label: '回'
        },
        {
          value: '藏',
          label: '藏'
        },
        {
          value: '维',
          label: '维'
        },
        {
          value: '苗',
          label: '苗'
        },
        {
          value: '彝',
          label: '彝'
        },
        {
          value: '壮',
          label: '壮'
        },
        {
          value: '依',
          label: '依'
        },
        {
          value: '朝',
          label: '朝'
        },
        {
          value: '满',
          label: '满'
        },
        {
          value: '侗',
          label: '侗'
        },
        {
          value: '瑶',
          label: '瑶'
        },
        {
          value: '白',
          label: '白'
        },
        {
          value: '家',
          label: '家'
        },
        {
          value: '尼',
          label: '尼'
        }
      ],
      isAddInfo: true, // 是否是添加老兵（true:添加老兵，false:编辑老兵信息）
      veteranId: '', // 老兵id
      uploadData: { auth: 'test' }
    }
  },
  created() {
    this.initFormData()
  },
  methods: {
    handleRemovePicture(index) {
      this.addForm.picture.splice(index, 1)
    },
    open(id) {
      if (id) {
        this.title = '修改老兵信息'
        this.$nextTick(() => {
          this.getVeteranDetail(id)
        })
        this.isAddInfo = false
        this.veteranId = id
      } else {
        this.title = '添加老兵信息'
        this.isAddInfo = true
      }
      this.visible = true
    },
    handleClose() {
      this.initFormData()
      this.$refs.htmlEditor.html = ''
    },
    initFormData() {
      this.addForm.avatar = ''
      this.addForm.bornDate = ''
      this.addForm.deathDate = ''
      this.addForm.detailId = ''
      this.addForm.enlistDate = ''
      this.addForm.enlistPlace = ''
      this.addForm.findDate = ''
      this.addForm.hometown = ''
      this.addForm.introduction = ''
      this.addForm.isWhampoa = ''
      this.addForm.liked = false
      this.addForm.name = ''
      this.addForm.nation = ''
      this.addForm.number = ''
      this.addForm.picture = []
      this.addForm.position = ''
      this.addForm.poster = ''
      this.addForm.prefectInfo = ''
      this.addForm.sacrificeDate = ''
      this.addForm.sacrificePlace = ''
      this.addForm.sex = '男'
      this.addForm.source = ''
      this.addForm.veteranStatus = ''
      this.addForm.waitFind = ''
    },
    async getVeteranDetail(id) {
      const form = {}
      const res = await veteranDetail(form, id)
      const { code, data } = res
      if (code === 200) {
        const {
          avatar,
          bornDate,
          deathDate,
          detailId,
          enlistDate,
          enlistPlace,
          findDate,
          hometown,
          introduction,
          isWhampoa,
          liked,
          name,
          nation,
          number,
          picture,
          position,
          poster,
          prefectInfo,
          sacrificeDate,
          sacrificePlace,
          sex,
          source,
          veteranStatus,
          waitFind
        } = data
        this.addForm.avatar = avatar || ''
        this.addForm.bornDate = bornDate
        this.addForm.deathDate = deathDate
        this.addForm.detailId = detailId
        this.addForm.enlistDate = enlistDate
        this.addForm.enlistPlace = enlistPlace
        this.addForm.findDate = findDate
        this.addForm.hometown = hometown
        this.addForm.introduction = introduction || ''
        this.addForm.isWhampoa = isWhampoa
        this.addForm.liked = liked
        this.addForm.name = name
        this.addForm.nation = nation
        this.addForm.number = number
        this.addForm.picture = picture || []
        this.addForm.position = position || ''
        this.addForm.poster = poster || ''
        this.addForm.prefectInfo = prefectInfo
        this.addForm.sacrificeDate = sacrificeDate || ''
        this.addForm.sacrificePlace = sacrificePlace || ''
        this.addForm.sex = sex
        this.addForm.source = source
        this.addForm.veteranStatus = veteranStatus
        this.addForm.waitFind = waitFind
        this.$refs.htmlEditor.setHtml(`<p>${introduction}</p>`)
      }
    },
    // 图片上传成功的回调
    handleAvatarSuccess(res) {
      console.log(res)
      if (res.code === 200) {
        this.$set(this.addForm, 'avatar', `${host}/file/browse/${res.data}`)
      } else {
        this.$message.error(res.message)
      }
    },
    handlePictureSuccess(res) {
      if (res.code === 200) {
        this.addForm.picture.push(`${host}/file/browse/${res.data}`)
      } else {
        this.$message.error(res.message)
      }
    },
    handlePosterSuccess(res) {
      if (res.code === 200) {
        this.$set(this.addForm, 'poster', `${host}/file/browse/${res.data}`)
      } else {
        this.$message.error(res.message)
      }
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 4
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 4MB!')
      }
      return isLt2M
    },
    // 添加/修改老兵
    async submit() {
      this.$refs.addForm.validate(async (valid) => {
        if (valid) {
          if (this.isAddInfo) {
            // 添加老兵
            this.addForm.id = ''
            const res = await addVeteranInfo(this.addForm)
            if (res.code === 200) {
              this.$emit('addSuccess')
              this.visible = false
              this.$message({
                message: res.message,
                type: 'success'
              })
            } else {
              this.$message(res.message)
            }
          } else {
            // 修改老兵信息
            this.addForm.id = this.veteranId
            const res = await updateVeteranInfo(this.addForm)
            if (res.code === 200) {
              this.$emit('addSuccess')
              this.$message({
                message: res.message,
                type: 'success'
              })
              this.visible = false
            } else {
              this.$message.error(res.message)
            }
          }
        }
      })
    }
  }
}
</script>

<style>
.picture-list {
  display: flex;
}
.pictures-item {
  position: relative;
  display: block;
  border: 1px solid #f3f3;
  margin: 0 5px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pictures-delete {
  position: absolute;
  cursor: pointer;
  right: -5px;
  top: -10px;
  color: #fff;
  background-color: red;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.avatar {
  width: 40px;
  height: 40px;
  display: block;
}
.avatars {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative;
  display: inline-block;
}
</style>
